import {
  Color,
  PaletteColorOptions,
  PaletteOptions,
  TypeText,
} from '@mui/material';
import { common } from '@mui/material/colors';

function getCssVariableValue(variable: string): string {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
}

const primary: PaletteColorOptions = {
  main: getCssVariableValue('--primary-main'),
  light: getCssVariableValue('--primary-light'),
  dark: getCssVariableValue('--primary-dark'),
  contrastText: getCssVariableValue('--primary-contrast-text'),
};

const secondary: PaletteColorOptions = {
  main: getCssVariableValue('--secondary-main'),
  light: getCssVariableValue('--secondary-light'),
  dark: getCssVariableValue('--secondary-dark'),
  contrastText: getCssVariableValue('--secondary-contrast-text'),
};

const success: PaletteColorOptions = {
  main: getCssVariableValue('--success-main'),
  light: getCssVariableValue('--success-light'),
  dark: getCssVariableValue('--success-dark'),
  contrastText: getCssVariableValue('--success-contrast-text'),
};

const info: PaletteColorOptions = {
  main: getCssVariableValue('--info-main'),
  light: getCssVariableValue('--info-light'),
  dark: getCssVariableValue('--info-dark'),
  contrastText: getCssVariableValue('--info-contrast-text'),
};

const warning: PaletteColorOptions = {
  main: getCssVariableValue('--warning-main'),
  light: getCssVariableValue('--warning-light'),
  dark: getCssVariableValue('--warning-dark'),
  contrastText: getCssVariableValue('--warning-contrast-text'),
};

const error: PaletteColorOptions = {
  main: getCssVariableValue('--error-main'),
  light: getCssVariableValue('--error-light'),
  dark: getCssVariableValue('--error-dark'),
  contrastText: getCssVariableValue('--error-contrast-text'),
};

const text: TypeText = {
  primary: getCssVariableValue('--text-primary'),
  secondary: getCssVariableValue('--text-secondary'),
  disabled: getCssVariableValue('--text-disabled'),
};

const grey: Partial<Color> = {
  50: getCssVariableValue('--grey50'),
  100: getCssVariableValue('--grey100'),
  200: getCssVariableValue('--grey200'),
  300: getCssVariableValue('--grey300'),
  400: getCssVariableValue('--grey400'),
  500: getCssVariableValue('--grey500'),
  600: getCssVariableValue('--grey600'),
  700: getCssVariableValue('--grey700'),
  800: getCssVariableValue('--grey800'),
  900: getCssVariableValue('--grey900'),
  A100: getCssVariableValue('--grey-a100'),
  A200: getCssVariableValue('--grey-a200'),
  A400: getCssVariableValue('--grey-a400'),
  A700: getCssVariableValue('--grey-a700'),
};

const palette: PaletteOptions = {
  primary,
  secondary,
  success,
  info,
  warning,
  error,
  text,
  grey,
  divider: grey[300],
  background: {
    default: common.white,
    paper: common.white,
  },
};

export default palette;

const CasaAI = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='118'
      height='121'
      viewBox='0 0 118 121'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3772 21.846C-2.79193 43.259 -0.679371 86.5833 25.0928 104.446C49.3064 121.228 66.2821 113.989 100.384 92.9398C103.641 90.9293 106.488 88.2601 108.876 85.1055C106.147 89.5042 102.636 93.1816 98.445 95.7685C64.3428 116.818 47.3671 124.056 23.1535 107.274C-3.01305 89.1386 -4.79034 44.7557 9.05317 23.7072C9.47241 23.0697 9.91406 22.4493 10.3772 21.846ZM10.3772 21.846C10.5787 21.5183 10.7838 21.1957 10.9925 20.8785C24.836 -0.170051 63.1266 -2.63709 91.534 13.1199C120.993 29.4604 123.264 66.096 108.876 85.1055C121.047 65.4848 117.652 31.5117 89.5947 15.9486C62.0476 0.668748 25.2068 2.5259 10.3772 21.846Z'
        fill='#DF7AF8'
      />
      <path
        d='M64.9402 114.139C30.8026 123.641 5.01061 94.5655 2.91372 64.0764C0.816833 33.5873 21.7984 4.47101 52.2963 4.54506C95.259 4.64938 116.796 45.7076 113.62 67.1419C106.751 113.515 94.3255 105.96 64.9402 114.139Z'
        fill='#F2A9FA'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M60.2369 111.574C90.6847 111.574 124.22 73.2444 109.199 52.8966C103.052 44.5689 100.176 37.4644 97.6756 31.2899C93.0688 19.9116 89.7407 11.6915 69.5788 4.79505C40.0906 -5.29151 2.94346 27.2234 6.60664 62.0053C7.92939 74.5648 11.8951 81.475 18.5039 90.652C19.5755 92.1401 20.5357 93.839 21.5048 95.5539C23.3118 98.7512 25.1501 102.004 27.8008 104.049C35.6695 110.12 48.9331 111.574 60.2369 111.574ZM63.0438 108.438C91.8033 108.438 109.193 75.4542 109.193 61.7544C109.193 57.2093 106.335 53.2075 103.574 49.3418C101.944 47.0598 100.348 44.8253 99.394 42.5545C92.0167 24.9949 80.8149 12.1469 59.486 12.1469C30.7264 12.1469 8.99266 31.3662 8.99266 59.3421C8.99266 68.5256 11.1132 71.8254 14.4258 76.9802C16.688 80.5003 19.5061 84.8855 22.5845 92.6004C29.2607 109.332 45.5526 108.88 58.1381 108.531C59.8534 108.483 61.4999 108.438 63.0438 108.438Z'
        fill='#FBD6FF'
      />
      <path
        d='M109.192 61.7539C109.192 75.4537 91.8029 108.437 63.0433 108.437C50.1717 108.437 30.1701 111.612 22.584 92.5999C14.9979 73.5878 8.99219 74.7966 8.99219 59.3416C8.99219 31.3657 30.726 12.1465 59.4855 12.1465C80.8145 12.1465 92.0162 24.9945 99.3935 42.5541C101.964 48.6715 109.192 54.5258 109.192 61.7539Z'
        fill='#270D9F'
      />
      <path
        d='M101.189 46.2302C105.428 57.8718 100.82 91.3145 76.3253 100.269C65.3622 104.277 49.3086 113.203 36.9657 99.4089C24.6228 85.6151 19.8816 88.5123 15.1004 75.3792C6.44571 51.6063 19.0112 28.5073 43.5063 19.5525C61.6727 12.9114 75.1882 20.3414 86.9039 32.9659C90.9854 37.364 98.9533 40.088 101.189 46.2302Z'
        fill='#432F9F'
      />
      <path
        d='M88 50.8105V50.8105C90.9079 50.8105 93.2652 53.1679 93.2652 56.0758V69.615C93.2652 72.5229 90.9079 74.8802 88 74.8802V74.8802V50.8105Z'
        fill='currentColor'
      />
      <path
        d='M93.2617 56.0752V56.0752C96.1696 56.0752 98.527 58.4325 98.527 61.3404V64.3491C98.527 67.2571 96.1696 69.6144 93.2617 69.6144V69.6144V56.0752Z'
        fill='currentColor'
      />
      <path
        d='M31.582 50.8105V50.8105C28.6741 50.8105 26.3168 53.1679 26.3168 56.0758V69.615C26.3168 72.5229 28.6741 74.8802 31.582 74.8802V74.8802V50.8105Z'
        fill='currentColor'
      />
      <path
        d='M26.3203 56.0752V56.0752C23.4124 56.0752 21.0551 58.4325 21.0551 61.3404V64.3491C21.0551 67.2571 23.4124 69.6144 26.3203 69.6144V69.6144V56.0752Z'
        fill='currentColor'
      />
      <path
        d='M29.3261 71.8994C29.3261 78.9163 34.6961 83.8493 42.0327 84.6052C42.0327 84.6052 50.4107 85.4725 59.1907 85.4725C67.2506 85.4725 76.177 84.6052 76.177 84.6052C83.1108 83.8485 88.8828 78.9163 88.8828 71.8994V53.0987C88.8828 46.0819 83.1833 40.7078 76.177 40.3929C76.177 40.3929 68.1171 39.5264 59.1907 39.5264C49.8086 39.5264 42.0327 40.3929 42.0327 40.3929C35.1265 40.8518 29.3261 46.0819 29.3261 53.0987V71.8994Z'
        fill='currentColor'
      />
      <path
        d='M36.8625 68.1308C36.8625 72.96 40.8542 76.3551 46.3077 76.8753C46.3077 76.8753 52.5354 77.4722 59.0618 77.4722C65.053 77.4722 71.6882 76.8753 71.6882 76.8753C76.8423 76.3545 81.1328 72.96 81.1328 68.1308V55.1915C81.1328 50.3623 76.8962 46.6637 71.6882 46.447C71.6882 46.447 65.6971 45.8506 59.0618 45.8506C52.0878 45.8506 46.3077 46.447 46.3077 46.447C41.1742 46.7628 36.8625 50.3623 36.8625 55.1915V68.1308Z'
        fill='white'
      />
      <circle cx='48.1337' cy='56.8232' r='3.00871' fill='#02001C' />
      <circle cx='70.6962' cy='56.8232' r='3.00871' fill='#02001C' />
      <path
        d='M70.6769 65.4688H48.1328C48.1328 67.7253 48.885 72.9767 58.9032 72.9767C68.9348 72.9767 70.6853 68.4175 70.6769 65.4688Z'
        fill='#02001C'
      />
    </svg>
  );
};
export default CasaAI;

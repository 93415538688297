import "./styles/colors.scss";
import { CssBaseline } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';

import App from './App';
import { AppThemeProvider } from './contexts/AppTheme';
import * as ServiceWorkerRegistration from './sw-registration';

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);

root.render(
  <AppThemeProvider>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
    <CssBaseline />
    <Toaster position='top-right' containerStyle={{ top: 80 }} />
  </AppThemeProvider>
);

ServiceWorkerRegistration.register();

import React from 'react'
import { createSvgIcon } from '@mui/material'

const BrandTheme = createSvgIcon(
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9974 3.1665C8.66406 3.1665 2.66406 9.1665 2.66406 16.4998C2.66406 23.8332 8.66406 29.8332 15.9974 29.8332C17.2321 29.8332 18.1947 28.8385 18.1947 27.5825C18.1947 26.9998 17.9547 26.4692 17.6121 26.0825C17.2254 25.6972 17.0281 25.2132 17.0281 24.5825C17.023 24.2891 17.0771 23.9976 17.1871 23.7255C17.297 23.4534 17.4607 23.2062 17.6682 22.9986C17.8757 22.7911 18.1229 22.6275 18.395 22.5175C18.6671 22.4075 18.9586 22.3534 19.2521 22.3585H21.9134C25.9814 22.3585 29.3201 19.0212 29.3201 14.9532C29.2841 8.51584 23.2787 3.1665 15.9974 3.1665Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.0026 9.83333C18.3708 9.83333 18.6693 9.53486 18.6693 9.16667C18.6693 8.79848 18.3708 8.5 18.0026 8.5C17.6344 8.5 17.3359 8.79848 17.3359 9.16667C17.3359 9.53486 17.6344 9.83333 18.0026 9.83333Z"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3307 15.1668C23.6989 15.1668 23.9974 14.8684 23.9974 14.5002C23.9974 14.132 23.6989 13.8335 23.3307 13.8335C22.9625 13.8335 22.6641 14.132 22.6641 14.5002C22.6641 14.8684 22.9625 15.1668 23.3307 15.1668Z"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3307 11.1668C11.6989 11.1668 11.9974 10.8684 11.9974 10.5002C11.9974 10.132 11.6989 9.8335 11.3307 9.8335C10.9625 9.8335 10.6641 10.132 10.6641 10.5002C10.6641 10.8684 10.9625 11.1668 11.3307 11.1668Z"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66667 17.8333C9.03486 17.8333 9.33333 17.5349 9.33333 17.1667C9.33333 16.7985 9.03486 16.5 8.66667 16.5C8.29848 16.5 8 16.7985 8 17.1667C8 17.5349 8.29848 17.8333 8.66667 17.8333Z"
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'BrandTheme'
)

export default BrandTheme

const getVariableValue = (variable: string): string => {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(variable)
    .trim();
};

const updateVariable = (name: string, value: string) => {
  document.documentElement.style.setProperty(`--${name}`, value);
};

export { getVariableValue, updateVariable };

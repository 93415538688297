import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const TelecallHistory = lazy(() => import("../components/TelecallHistory"));

const telecallHistory: RouteObject = {
  path: "leads/telecall-history",
  element: <TelecallHistory />,
};

export default telecallHistory;